import React, { Component } from 'react'
import { InitativeCard, RecognitionCard, RecognitionCardV2, WellnessCardV2 } from '../PersonalProfile/style';
import PropTypes from 'prop-types';
import { ImageUrl } from '../../utils/constants';
import moment from 'moment';
// import {getLastSyncDetails} from "../../utils/methods";
import { AboutCard, OverviewCard, WellnessCard, InitativeCardV2 } from './styles';
import { convertMonthFromDate } from '../../utils/methods';
import { withTranslation } from 'react-i18next';
import SkeletonLoder from '../../components/common/skeletonLoder';

class OverviewV3 extends Component {
  render() {
    const { userModal, user, interest, wellnessInitiatives, userRecognitionCount, history } = this.props;
    let TotalRecognitionPoints = 0;
    userRecognitionCount && userRecognitionCount.length>0 && userRecognitionCount.map((item) => {
      TotalRecognitionPoints = TotalRecognitionPoints + item.count;
    })
    let completedInitaitives = 0;
    wellnessInitiatives && wellnessInitiatives.length>0 && wellnessInitiatives.map((item) => {
      if (item.is_taken === 1) {
        completedInitaitives = completedInitaitives + 1;
      }
    })
    const RegistrationDate =user && user.created_at?moment(user.created_at).format("MM/DD/YYYY"):"NA";
    return (

      <div>
        <OverviewCard>
          <div className='view-card'>
            <div className='prof-details'>
              <div className='name-in'>{this.props.t("Profile Details")}</div>
              <button onClick={() => history.push('/settings')} className='user-edit-btn'>{this.props.t("Edit Profile")}</button>
            </div>
            <hr className='horizontal-line' />
            <div className='emp-detail'>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Full Name")}: </div>
                <div className='prof-data'>{user && user.fname} {user && user.lname}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Department")}: </div>
                <div className='prof-data'>{user && user.department !== null ? user.department : "NA"}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Company")}: </div>
                <div className='prof-data'>{user && user.company_name !== null ? user.company_name : "NA"}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Location")}: </div>
                <div className='prof-data'>{user && user.user_city !== null ? user.user_city : "NA"}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Email")}: </div>
                <div className='prof-data'>{user && user.mail !== null ? user.mail : "NA"}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Country")}: </div>
                <div className='prof-data'>{user && user.country !== null ? user.country : "NA"}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Contact")}: </div>
                <div className='prof-data'>{user && user.phone_number !== null ? user.phone_number : "NA"}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Birthday")}: </div>
                <div className='color'>{user && user.birthday !== null ? convertMonthFromDate(moment(user.birthday).format("DD MMMM YYYY"), this.props) : "NA"}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Registration Date")}: </div>
                <div className='prof-data'>{user && user.created_at? RegistrationDate: "NA"}</div>
              </div>
              <div className='fieldNames'>
                <div className='label-name-emp'>{this.props.t("Work Anniversary")}: </div>
                <div className='green'>{user && user.work_anniversary_date !== null ? convertMonthFromDate(moment(user.work_anniversary_date).format("DD MMMM YYYY"), this.props) : "NA"}</div>
              </div>
            </div>

          </div>
        </OverviewCard>
        {interest && interest.length > 0 &&
          <WellnessCard>
            <div className='wellness-interest'>
              <div className='wellness-title'>
                <span className='title-text'>{this.props.t("Wellness Interests")}</span>
                <span className='wellness-count'>{`(${("Total")} : ${interest.length})`}</span>
                <button onClick={() => history.push('/settings')} className='user-edit-btn'>{this.props.t("Edit Interest")}</button>
              </div>
              <hr className='horizontal-line' />
              <div className='interest-card-wrapper'>
                {interest && interest.length > 0 && interest.map((data, index) =>
                  (
                    <WellnessCardV2 key={index} marginRight={(index + 1) % 4 === 0 ? "0px" : "25px"}>
                      <div className='icon-wrapper'>
                        <div className="interest-icon">
                          <img src={`${ImageUrl}/${data.interest_icon}`} />
                        </div>
                      </div>
                      <div className='interest-name'>
                        <span >{this.props.t(data.interest_name)}</span>
                      </div>
                    </WellnessCardV2>
                  ))
                }
              </div>
            </div>
          </WellnessCard>}
        {user.bio && <AboutCard>
          <div className='about-user-section'>
            <div className='title-text'>
              {this.props.t("About")} {user.fname}
              <button onClick={() => userModal()} className='user-edit-btn'>{this.props.t("Edit About")}</button>
            </div>
            <hr className='horizontal-line' />
            <div className='about-user-details'>
              {this.props.t(user.bio)}
            </div>
          </div>
        </AboutCard>}
        {this.props.companyBranding?.company_branding?.show_biometric ?
          <InitativeCard justify>
            <div className='company-initative'>
              <div className='heading'>
                {this.props.t("My")} {moment().format('YYYY')} {this.props.t("Company Initiatives")} &nbsp;<div className="total">{`(${this.props.t("Completed")} : ${completedInitaitives})`}</div>
              </div>
              <hr className='horizontal-line2' />
              <div className='fitness-wrapper'>
                {
                  wellnessInitiatives && wellnessInitiatives?.length>0 ? wellnessInitiatives.map((item, index) => (
                    <InitativeCardV2 key={index} marginRight={(index + 1) % 3 === 0 ? "0px" : "25px"} color={item.font_color}>
                      <div className='rectangle-org'>
                        <img src={`${ImageUrl}/${item.logo}`} />
                      </div>
                      <div className='syncing-title-value'>{this.props.t(item.name)}
                        {item.is_taken?<div className='points-value'>{item.date? `${this.props.t("Completed on")} ${convertMonthFromDate(moment(item.date).format('MMM DD, YYYY'), this.props)}`: this.props.t("Completed")}</div>:<div className='points-value'>+{item.points} {("pts")}</div>}
                      </div>
                      {item.is_taken ?
                        <img className='right-mark' src='/public/images/Right.svg' /> :
                        <img className='right-mark' src='/public/images/Wrong.svg' />
                      }
                    </InitativeCardV2>
                  )):<SkeletonLoder width={'1196px'} height={'300px'}></SkeletonLoder>
                }
              </div>
            </div>
          </InitativeCard>:null}
        {this.props.companyBranding?.company_branding?.show_recognition ?
          <RecognitionCard>
            <div className='recognize-received'>
              <div className='heading2'>
                {this.props.t("My")} {moment().format('YYYY')} {this.props.t("Recognitions Received")} <div style={{marginLeft: '25px'}} className="count">{`(${this.props.t("Total")} : ${TotalRecognitionPoints})`}</div>
              </div>
              <hr className='horizontal-line2' />
              <div className='welcome-wrapper'>
                {
                  userRecognitionCount?.length>0 ? userRecognitionCount?.map((item, index) => (<>
                    {item.count > 0 ? (<RecognitionCardV2 key={index} marginRight={(index + 1) % 4 === 0 ? "0px" : "25px"}>
                      <div className='img'>
                        <img src={`${ImageUrl}/${item.icon}`} />
                      </div>
                      <div className='welldone-title'>{this.props.t(item.name)}</div>
                      <div className='point'>{this.props.t(item.count)}</div>
                    </RecognitionCardV2>) : null} </>)
                  ):<SkeletonLoder width={'1196px'} height={'300px'}></SkeletonLoder>
                }
              </div>
            </div>
          </RecognitionCard>:null}
      </div>
    )
  }
}
OverviewV3.propTypes = {
  userModal: PropTypes.func,
  user: PropTypes.object,
  interest: PropTypes.object,
  wellnessInitiatives: PropTypes.array,
  userRecognitionCount: PropTypes.array,
  history: PropTypes.object.isRequired,
  t: PropTypes.func,
  companyBranding: PropTypes.object
};

export default ((withTranslation())(OverviewV3));